<style lang="scss" scoped>
.clipboard-copy-container {
  position: relative;
  display: flex;
  width: fit-content;
  gap: 10px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  .copyImage {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  &::after {
    content: 'Click to copy';
    font-size: 0.8em;
    display: none;
    width: 80px;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
  }
  &.active::after {
    display: flex;
    content: 'Copied';
  }

  &:hover::after {
    display: flex;
  }
}
</style>
<template>
  <span class="clipboard-copy-container" @click="copy" :class="{ active: copied }">
    <span class="clipboard-copy" ref="content"><slot></slot></span
    ><img src="~@/assets/icons/copy.svg" alt="" class="copyImage" />
  </span>
</template>
<script>
export default {
  name: 'ClipboardCopy',
  props: { target: { default: false } },
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    copy() {
      let el;
      if (this.target) {
        console.log('Has target');
        el = document.querySelector(this.target);
      } else {
        el = this.$refs.content;
      }
      let textArea = document.createElement('textarea');
      console.log(el);
      textArea.value = el.innerHTML;
      el.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      el.removeChild(textArea);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 1200);
    },
  },
};
</script>
