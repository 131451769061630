<template>
  <div class="switch" :class="{ yellow: yellowSwitch }">
    <label class="switch" :for="'switch_' + id">
      <input type="checkbox" v-model="val" :value="v" :id="'switch_' + id" />
      <span class="slider"></span>
    </label>
  </div>
  <label :for="'switch_' + id" v-if="hasSlot()">
    <slot></slot>
  </label>
</template>

<script type="text/ecmascript-6">
export default {
  name: 'SwitchInput',
  emits: ['update:modelValue'],
  props: { modelValue: {}, toggle: {}, v: { default: 1 },yellowSwitch: { default: false }},
  data() {
    return {
      id: null,
    };
  },
  computed: {
    val: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  mounted() {
    this.id = Math.random().toString(16).slice(2);
  },
  methods: {
    hasSlot(name = 'default') {
      return !!this.$slots[name];
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/scss/components/switchInput';
</style>
