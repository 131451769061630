<template>
  <div
    class="input-row"
    :class="{
      'clear-button-hide': hideClear,
      'search-input': searchInput,
    }"
  >
    <div
      class="search-input-box"
      v-if="searchInput"
      :class="{
        'show-search-icon': modelValue,
      }"
    >
      <img src="@/assets/icons/searchIcon.svg" />
    </div>
    <input
      :type="inputType"
      :placeholder="searchInput ? ' ' : placeHolder"
      :id="'input' + id"
      v-model="val"
      :min="minInput"
      :max="maxInput"
    />

    <div class="clear-input" :class="{ show: modelValue }" @click="clearModelValue" v-if="!hideClear"></div>
  </div>
</template>

<script>
export default {
  name: 'InputComponent',
  props: {
    searchInput: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
      default: 'text',
    },
    placeHolder: {
      type: String,
      default: 'placeHolder',
    },
    hideClear: {
      type: Boolean,
      default: false,
    },
    minInput: {
      type: Number,
    },
    maxInput: {
      type: Number,
    },
    errorMessage: {},
    modelValue: {},
  },
  data() {
    return {
      id: null,
    };
  },
  emits: ['update:modelValue'],
  mounted() {
    this.id = Math.random().toString(16).slice(2);
  },
  computed: {
    val: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    clearModelValue() {
      this.val = '';
    },
  },
};
</script>
