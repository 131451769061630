import { createRouter, createWebHistory } from 'vue-router';
import NotFound from '@/views/NotFound';

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Index.vue'),
  },
  {
    path: '/:id',
    name: 'IndexWithId',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Index.vue'),
  },
  {
    path: '/online',
    name: 'Online',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Online.vue'),
  },
  {
    path: '/online/:id',
    name: 'OnlineWithId',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Online.vue'),
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Statistics.vue'),
  },
  {
    path: '/userManagement',
    name: 'UserManagement',
    component: () => import(/* webpackChunkName: "manage" */ '@/views/UserManagement.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/Admin.vue'),
  },
  {
    path: '/admin/info',
    name: 'AdminInfo',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Info.vue'),
  },
  {
    path: '/admin/log',
    name: 'AdminLog',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Log.vue'),
  },
  {
    path: '/admin/online-stores',
    name: 'AdminOnlineStores',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/OnlineStores.vue'),
  },
  {
    path: '/admin/online-stores/creator',
    name: 'AdminOnlineStoresCreator',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/OnlineStoreCreator.vue'),
  },
  {
    path: '/admin/online-stores/creator/:id',
    name: 'AdminOnlineStoresEditor',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/OnlineStoreCreator.vue'),
  },
  {
    path: '/admin/dealers',
    name: 'AdminDealers',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Dealers.vue'),
  },
  {
    path: '/admin/conflicts',
    name: 'AdminConflicts',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Conflicts.vue'),
  },
  {
    path: '/admin/settings',
    name: 'AdminSettings',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Settings.vue'),
  },
  {
    path: '/admin/models',
    name: 'AdminModels',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Models.vue'),
  },
  {
    path: '/admin/option-filters',
    name: 'AdminOptionFilters',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/OptionFilters.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
