<template>
  <div class="auth">
    <nav>
      <img src="~@/assets/quadraturaLogin.png" alt="Quadratura Logo" />
    </nav>
    <div class="middle">
      <div class="left">
        <img src="~@/assets/icons/loginLeft.svg" alt="left" />
      </div>
      <div>404 - Not found</div>
      <div class="right">
        <img src="~@/assets/icons/loginRight.svg" alt="right" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotFound'
};
</script>

<style lang="scss" scoped>
@import '@/scss/pages/login';
</style>

