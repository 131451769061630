import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import Directives from './directives';

import SwitchInput from '@/components/SwitchInput';
import ClipboardCopy from '@/components/ClipboardCopy';
import InputComponent from '@/components/input-component/InputComponent.vue';

window.noImageBMW = require('@/assets/icons/bmw.svg');
window.noImageMINI = require('@/assets/icons/mini.svg');
window.fallbackImage = require('@/assets/photos/carFallBack.png');

axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

let userData = null;

axios
  .get('/api/user-info')
  .then((response) => {
    userData = response.data;
  })
  .finally(() => {
    const app = createApp(App);
    app.config.globalProperties.$http = axios;
    app.config.globalProperties.$userData = () => {
      if (userData === null) {
        return axios.get('/api/user-info').then((response) => {
          return (userData = response.data);
        });
      } else {
        return new Promise((resolve) => {
          resolve(userData);
        });
      }
    };
    Directives(app);
    app.component('SwitchInput', SwitchInput);
    app.component('ClipboardCopy', ClipboardCopy);
    app.component('InputComponent', InputComponent);
    app.use(router).mount('#app');
  });
